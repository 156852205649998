import React, {Fragment, useEffect, useRef, useState} from 'react';
import './App.css';
import { StylesProvider } from '@material-ui/core/styles';
import Div100vh from "./utils/lib";
import { SlideUpPanel } from "./SlideUpPanel/SlideUpPanel";
import { Loader } from "./Loader/Loader";
import $ from 'jquery';
import firebase from "./utils/firebase";
import 'firebase/firestore';
import 'firebase/auth';
/* import { useParams } from 'react-router-dom'; */
import color from 'color'
import {Home} from "./Home/Home";


function clearLoader() {
    $("#loading").fadeOut();
    $("#loading .object").delay(700).fadeOut("slow");
    $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
}


function App() {

    const [login, setLogin] = useState(true);
    const [user, setUser] = useState({});
    const [dontResize, setDontResize] = useState(false);
    const [theHeight, setHeight] = useState(400)
    const [listHeight, setListHeight] = useState(400)
    const [sellerID, setSellerID] = useState(window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1));

    const [colors, setColors] = useState({primaryColor: '#9146F7', textColor: '#ffffff'})
    const theAppRef = useRef(null);

   /*  const { id } = useParams();
 */
    useEffect(() => {

        if (sellerID){
            let db = firebase.firestore();
            db.collection('users').doc(sellerID).get().then(function (doc) {
                let theUser = doc.data();
                let theColor = theUser.color;
                let colorObj = color(theColor);
                let textColor = colorObj.isLight() ? '#000000' : '#ffffff';

                setColors({textColor, primaryColor: theColor, isLight: colorObj.isLight()});


                theUser.id = doc.id;
                setUser(theUser);
                setLogin(true)

                clearLoader();
            })
        }

        setTimeout(() => {
            let height = theAppRef.current.state.style.height;
            var thenum = height.replace(/\D/g, "");
            let panel = parseFloat( (thenum * 0.85).toFixed(0));
            setHeight(thenum)
            setListHeight(panel)
            setDontResize(true)
        }, 500);
    }, [])


    return (
        <StylesProvider injectFirst>
            <Div100vh dontResize={dontResize} ref={theAppRef} className="App">
                <Loader colors={colors} />
                {login &&
                <Fragment>

                    <Home colors={colors} user={user} />
                    <SlideUpPanel listHeight={listHeight} theHeight={theHeight} user={user} colors={colors} />

                </Fragment>
                }
            </Div100vh>
        </StylesProvider>
    );
}

export default App;
