import React, { useEffect} from 'react';
import styles from './pageballs.module.scss'

export function PageBalls({index, steps, colors}) {

    useEffect(() => {

    },[]);

    return (
        <div className={styles.ballsWrapper}>
            {Array.from(Array(steps), (e,i) => {
                return (
                    <div key={i} style={{marginLeft: i === 0 ?  0 : {}, backgroundColor: colors.textColor }}  className={index === i ? styles.pageBallSelected : styles.pageBall}/>
                );
            })}
        </div>
    )
}