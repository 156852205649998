import React from 'react';
import './loader.scss'

export function Loader({colors}) {



    return (
        <div style={{backgroundColor: colors.primaryColor}} id="loading">
            <div id="loading-center">
                <div id="loading-center-absolute">
                    <div style={{borderTop: '5px solid ' + colors.textColor, borderLeft: '5px solid ' + colors.textColor}} className="object" id="object_four"></div>
                    <div style={{borderTop: '5px solid ' + colors.textColor, borderLeft: '5px solid ' + colors.textColor}} className="object" id="object_three"></div>
                    <div style={{borderTop: '5px solid ' + colors.textColor, borderLeft: '5px solid ' + colors.textColor}} className="object" id="object_two"></div>
                    <div style={{borderTop: '5px solid ' + colors.textColor, borderLeft: '5px solid ' + colors.textColor}} className="object" id="object_one"></div>
                </div>
            </div>
        </div>
    )
}