export function getBin() {
    const cardnumber = document.getElementById("cardNumber").value;

    return cardnumber.substring(0,6);
}

export function validateMonth(month) {
    var re = /0[1-9]|1[0-2]/;
    return re.test(String(month).toLowerCase());
}

export function validateYear(year) {
    var theYear = parseFloat(year);
    var today = new Date();
    var actualYear = today.getFullYear();
    if (theYear >= actualYear && theYear <= 2030){
        return true
    }else {
        return false
    }
}

export function validateSecurityCode(code) {
    var re = /^[0-9]{3,4}$/;
    return re.test(String(code).toLowerCase());
}

export function validateCreditCard(cardNumber) {
    var re = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
    return re.test(String(cardNumber).toLowerCase());
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}