import React, { useState, useEffect } from 'react';
import styles from './home.module.scss'
import logoLight from '../img/logoLightItesa.svg'
import logoDark from '../img/logoDarkItesa.svg';
import defaultBG from '../img/defaultBG.jpg';


export function Home({ colors, user }) {
    const { bgImageMobile, bgImageDesktop } = user;
    const responsiveBgImage = (window.innerWidth > 765) ? bgImageDesktop : bgImageMobile;

    useEffect(() => {

    });

    return (
        <div
            className={styles.home}
            style={{
                backgroundImage: `url(${responsiveBgImage ? responsiveBgImage : defaultBG})`,
                backgroundColor: colors.primaryColor
            }}
        >
            <div
                className={styles.gradient}
                style={{ background: 'linear-gradient(' + colors.primaryColor + ',rgba(255, 255, 255, 0))' }}
            />
            <img
                className={styles.logo} alt="logo"
                src={colors.isLight ? logoDark : logoLight}
            />
        </div>
    )
}