import React, { Fragment, useEffect, useRef, useState } from "react";
import Animated from "animated/lib/targets/react-dom";
import Interactable from "react-interactable/noNative";
import styles from "./slideup.module.scss";
import {
  Box,
  Button, createMuiTheme,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { PageBalls } from "../PageBalls/PageBalls";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import firebase from "../utils/firebase";
import "firebase/firestore";
import "firebase/auth";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { MercadoPagoForm } from "./MercadoPagoForm";
import { Scrollbars } from 'react-custom-scrollbars';
import { getOrderToSend } from "../utils/FireBaseServices";

import {withStyles} from "@material-ui/styles";
import makeStyles from "@material-ui/styles/makeStyles";



let posX = new Animated.Value(0);
const interStyle = {
  inter: {
    position: "fixed",
    bottom: 0,
    zIndex: 1400,
  },
  interAbs: {
    position: "fixed",
    bottom: 0,
    zIndex: 1400,
  },
};

const orderInitialState = {
  shop: {},
  send: "",
  address: "",
  address2: "",
  name: "",
  phone: "",
  mail: "",
  comments: "",
  products: [],
  total: 0,
};

let db = firebase.firestore();

export function SlideUpPanel({ user, colors , theHeight, listHeight}) {
  const { id } = user;

  const [shops, setShops] = useState([]);
  const [shop, setShop] = useState({ key: "" });
  const [products, setProducts] = useState([]);
  const componentRef = useRef(null);
  const panelRef = useRef(null);
  const [panelHeight, setPanelHeight] = useState(150);
  const [panelOpen, setPanelOpen] = useState(false);
  const [toggleStyle, setToggleStyle] = useState({});
  const [index, setIndex] = useState(0);
  const titles = [
    "🛒 Empezar",
    "🛒 Agregar productos",
    "🛒 Ingresar dirección",
    "🛒 Ingresar datos",
    "🛒 Ingresar datos",
    "🛒 Datos del pedido",
    "Datos de tu tarjeta",
  ];
  const [title, setTitle] = useState(0);
  const [error, setError] = useState(false);
  const [refreshOnSuccess, setRefreshOnSuccess] = useState(false);
  const [order, setOrder] = useState(orderInitialState);
  const [cashPay, setCashPay] = useState(false)

  useEffect(() => {
    if (id) {
      db.collection("shops")
        .where("userID", "==", id)
        .onSnapshot(function (docs) {
          let theData = [];
          docs.forEach((doc) => {
            const shop = doc.data();
            shop.key = doc.id;

            theData = [...theData, shop];
          });
          setShops(theData);
        });

      db.collection("products")
        .where("userID", "==", id)
        .onSnapshot(function (docs) {
          let theData = [];
          docs.forEach((doc) => {
            const prod = doc.data();
            prod.key = doc.id;
            theData = [...theData, prod];
          });
          setProducts(theData);
        });
    }
  }, [id]);

  useEffect(() => {
    if (products) {
      let list = products.filter(
        (p) => p.shops.findIndex((s) => s === shop.key) > -1
      );
      list.map((i) => (i.quantity = 0));
      setOrder({
        ...order,
        shop,
        products: list,
      });
    }
  }, [shop]);

  /*  useEffect(() => {
        //control de permiso para continuar en funcion del índice de pantalla
        setAllowContinue(autorization);
      }, [order, index]);*/

  const handleChangeShop = (e) => {
    if (e.target.value) {
      refreshHeight();
      setShop(shops.find((s) => s.key === e.target.value));
      setError(false);
    } else {
      setShop({});
    }
    setOrder(orderInitialState);
  };

  const handleQuantity = (idx, op) => {
    let aux = order.products;
    let total = order.total;
    if (op) {
      aux[idx].quantity++;
      total += parseInt(aux[idx].price);
      setOrder({ ...order, products: aux, total });
    } else if (aux[idx].quantity > 0) {
      aux[idx].quantity--;
      total -= parseInt(aux[idx].price);
      setOrder({ ...order, products: aux, total });
    }
  };

  const updatePanel = () => {
    setToggleStyle({
      fontSize: "30px",
      transform: [{ rotate: toggleRotation(panelRef.current.offsetHeight) }],
      WebkitTransform: [
        { rotate: toggleRotation(panelRef.current.offsetHeight) },
      ],
    });
    setPanelHeight(panelRef.current.offsetHeight);
  };

  const refreshHeight = () => {
    setTimeout(() => {
      updatePanel();
      componentRef.current.snapTo({ index: 1 });
    }, 100);
  };
  const refreshMaxHeight = () => {
    setTimeout(() => {
      updatePanelToTotalHeight();
      componentRef.current.snapTo({ index: 1 });
    }, 100);
  };

  useEffect(() => {

    if (panelOpen === true ) {
      refreshHeight();
    }
    
    if (index === 1) {
      if (!order.shop.name || !order.send) {
        setIndex((prevIndex) => prevIndex - 1);
        setError(true);
      }
    } else if (index === 2) {
      if (order.total < 1) {
        setIndex((prevIndex) => prevIndex - 1);
        // setError(true);
      } else {
        if (order.send === '1') {
          setOrder({ ...order, address: "", address2: "" })
          setIndex(3);
        }
      }
    } else if (index === 3) {
      if ((order.send === '0') && (!order.address || !order.address2)) {
        setIndex((prevIndex) => prevIndex - 1);
        setError(true);
      }
    } else if (index === 4) {
      if (!order.name || !order.phone) {
        setIndex((prevIndex) => prevIndex - 1);
        setError(true);
      }
    } else if (index === 5) {
      if (!order.mail || !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(order.mail)) {
        setIndex((prevIndex) => prevIndex - 1);
        setError(true);
      }
    } else if (index === 6) {
      if (!order.shop.name || !order.send) {
        setIndex((prevIndex) => prevIndex - 1);
        setError(true);
      }
    }
  }, [index]);

  const handleChange = (e) => {
    setOrder({
      ...order,
      [e.target.name]: e.target.value,
    });
    setError(false);
  };

  useEffect(() => {
    setTimeout(() => {
      updatePanel();
    }, 200);
  }, []);

  const updatePanelToTotalHeight = () => {
    setToggleStyle({
      fontSize: "30px",
      transform: [{ rotate: toggleRotation(theHeight) }],
      WebkitTransform: [
        { rotate: toggleRotation(theHeight) },
      ],
    });
    setPanelHeight(theHeight);
  };
  const toggleOpen = () => {
    if (panelOpen) {
      componentRef.current.snapTo({ index: 0 });
    } else {
      componentRef.current.snapTo({ index: 1 });
    }
  };

  const toggleRotation = (height) => {
    if (height < 80) {
      height = 81;
    }
    return posX.interpolate({
      inputRange: [0, (80 - height) * -1],
      outputRange: ["0deg", "180deg"],
    });
  };

  const onDrawerSnap = (event) => {
    if (event.index) {
      setPanelOpen(true);
      componentRef.current.props.style.position = "fixed";
    } else {
      setPanelOpen(false);
      componentRef.current.props.style.position = "fixed";
    }
  };

  const handleChangeIndex = (newIndex) => {
    if (newIndex === 2 && index === 3 && order.send === '1') {
      setIndex(1);
    } else {
      setIndex(newIndex);
    }
  };

  const onNext = () => {
    setIndex((previndex) => previndex + 1);
  };

  const onPrev = () => {
    if (index === 3 && order.send === '1') {
      setIndex(1);
    } else {
      setIndex((previndex) => previndex - 1);
    }
  };

  const onCashPay = () =>{
    let db = firebase.firestore();
    let newOrder = {...order}
    newOrder.paid = false
    let orderToSend = getOrderToSend(user.id,newOrder)
    db.collection('orders').add(orderToSend)
    fetch('https://us-central1-itesacheckouts.cloudfunctions.net/cashPayEmail', {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({order: orderToSend, seller: user}), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    }).then(function (res) {
      res.json().then(function (val) {
        console.log(val)
      })
    })

  };


  const CssFormControl = withStyles({
    root: {
      "& label.Mui-focused": {
        color: colors.textColor
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: colors.textColor
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: colors.textColor
        },
        "&:hover fieldset": {
          borderColor: colors.textColor
        },
        "&.Mui-focused fieldset": {
          borderColor: colors.textColor
        }
      }
    }
  })(FormControl);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: colors.textColor,
        contrastText: colors.primaryColor,
      },
      secondary: {
        main: '#ff0013',
        contrastText: '#ffffff'
      },

    },
    typography: { useNextVariants: true },
  });


  // const sendOrderTest = () => {
  //   const orderToSend = getOrderToSend(id, order);
  //   console.log(orderToSend);
  //   db.collection('orders').add(orderToSend);
  // }

  return (
    <ThemeProvider theme={theme}>
      <Interactable.View
        id={"inter"}
        ref={componentRef}
        animatedValueY={posX}
        boundaries={{ top: 80 - panelHeight, bottom: 0, bounce: 1 }}
        snapPoints={[{ y: 0 }, { y: 80 - panelHeight }]}
        verticalOnly={true}
        dragEnabled={true}
        style={interStyle.inter}
        onSnap={onDrawerSnap}
      >
        <div className={styles.panelContainer}>
          <Paper style={{backgroundColor: colors.primaryColor}} elevation={3} ref={panelRef} className={styles.panel}>
            <div onClick={toggleOpen} className={styles.panelHeader}>
              <Animated.div style={toggleStyle}>
                <i
                  style={{ color: colors.textColor }}
                  className={"ion-md-arrow-dropup"}
                />
              </Animated.div>

              <div style={{ color: colors.textColor }} className={styles.startMessage}>
                <Grid container spacing={1}>
                  <Grid item>
                    <span >{titles[index]}</span>
                  </Grid>
                </Grid>
              </div>
            </div>
            <SwipeableViews
              enableMouseEvents={true}
              index={index}
              onChangeIndex={handleChangeIndex}
            >

              <div>
                {index === 0 && (
                  <Fragment>
                    <CssFormControl variant="outlined" className={colors.isLight ? styles.fieldDark : styles.field}>
                      <InputLabel htmlFor="shopID">
                        Seleccioná tu tienda
                      </InputLabel>
                      <Select
                        error={error}
                        name="shopID"
                        value={shop.key}
                        onChange={handleChangeShop}
                        label="Seleccioná tu tienda"
                        input={
                          <OutlinedInput
                            className={styles.selectInput}
                            labelWidth={150}
                          />
                        }
                      >
                        {shops.map((shop) => (
                          <MenuItem key={shop.key} value={shop.key} disabled={!shop.enabled}>
                            {" "}
                            {shop.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </CssFormControl>

                    <CssFormControl component="fieldset" className={colors.isLight ? styles.fieldDark : styles.field}>
                      <RadioGroup
                        aria-label="send"
                        name="send"
                        value={order.send}
                        onChange={handleChange}
                      >
                        {(shop.type === 0 || shop.type === 2) &&
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label="Envío a domicilio"
                          />
                        } {(shop.type === 1 || shop.type === 2) &&
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="Takeaway"
                          />
                        }
                      </RadioGroup>
                    </CssFormControl>
                  </Fragment>
                )}
              </div>

              <Scrollbars style={{height: index === 1 ? theHeight - 151.5 : 'unset'}}>
                {index === 1 &&
                  order.products.map((product, idx) => {
                    return (
                      <Box
                        className={styles.itemBox}
                        key={product.key}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        p={0}
                        m={0}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="start"
                          alignContent="flex-start"
                          flexDirection="column"
                          pr={0}
                          m={0}
                        >
                          <Typography color={"primary"} variant="subtitle1">
                            {product.name}
                          </Typography>
                          <Typography color={"primary"} variant="subtitle1">
                            ${product.price}
                          </Typography>
                        </Box>
                        <Box
                          borderRadius={5}
                          borderColor="primary.main"
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          border={1}
                        >
                          <Box p={1}>
                            <IconButton
                              onClick={() => handleQuantity(idx, false)}
                              aria-label="remove"
                            >
                              <RemoveIcon color={"primary"} fontSize="small" />
                            </IconButton>
                          </Box>
                          <Box p={1}>
                            <Typography color={"primary"} variant="subtitle1">
                              {product.quantity}
                            </Typography>
                          </Box>
                          <Box p={1}>
                            <IconButton
                              onClick={() => handleQuantity(idx, true)}
                              aria-label="add"
                            >
                              <AddIcon color={"primary"} fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  p={4}
                  m={0}
                  className={styles.paneltextgeneral}
                >
                  <Typography
                    color={"primary"}
                    className={styles.panelsubt}
                    variant="subtitle1"
                  >
                    Total $ {order.total}
                  </Typography>
                </Box>
              </Scrollbars>

              <div>
                <form>
                  <TextField
                    className={colors.isLight ? styles.fieldDark : styles.field}
                    error={error}
                    value={order.address}
                    onChange={handleChange}
                    name="address"
                    id={'address'}
                    type="text"
                    label="Ingresa tu dirección"
                    variant="outlined"
                    autoComplete="off"
                  />
                  <TextField
                      className={colors.isLight ? styles.fieldDark : styles.field}
                    required
                    error={error}
                    name="address2"
                    value={order.address2}
                    type="text"
                    label="Piso / Depto / Info extra"
                    variant="outlined"
                    onChange={handleChange}
                    autoComplete="off"
                    />
                </form>
              </div>
              
              <div>
                <form>
                  <TextField
                    required
                    name="name"
                    error={error}
                    value={order.name}
                    type="text"
                    label="Nombre completo"
                    variant="outlined"
                    className={colors.isLight ? styles.fieldDark : styles.field}
                    onChange={handleChange}
                    autoComplete="off"
                    />
                  <TextField
                    required
                    name="phone"
                    error={error}
                    value={order.phone}
                    type="number"
                    label="Celular"
                    variant="outlined"
                    className={colors.isLight ? styles.fieldDark : styles.field}
                    onChange={handleChange}
                    autoComplete="off"
                    inputProps={{ min: 0 }}
                  />
                </form>
              </div>
              <div>
                <form>
                  <TextField
                    required
                    name="mail"
                    error={error}
                    value={order.mail}
                    type="email"
                    label="Email"
                    variant="outlined"
                    className={colors.isLight ? styles.fieldDark : styles.field}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <TextField
                    name="comments"
                    value={order.comments}
                    label={order.send === '1' ? 'Hora de pick-up' : "Comentarios Adicionales"}
                    variant="outlined"
                    multiline
                    rows={2}
                    className={colors.isLight ? styles.fieldDark : styles.field}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </form>
              </div>
              <div>
                {index === 5 && (
                  <Fragment>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="start"
                      pl={4}
                      m={0}
                      style={{padding: 0, marginLeft: '6%'}}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="start"
                        p={0}
                        ml={1}
                      >
                        <Box>
                          <Typography
                            color={"primary"}
                            className={styles.paneltitledetails}
                            variant="subtitle1"
                          >
                            Total:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography color={"primary"} variant="subtitle1">
                            $ {order.total}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="start"
                        p={0}
                        ml={1}
                      >
                        <Box>
                          <Typography
                            color={"primary"}
                            className={styles.paneltitledetails}
                            variant="subtitle1"
                          >
                            Email:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                          color={"primary"} 
                          variant="subtitle1">
                            {order.mail}
                          </Typography>
                        </Box>
                      </Box>
                      {order.address && <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="start"
                        p={0}
                        ml={1}
                      >
                        <Box>
                          <Typography
                            color={"primary"}
                            className={styles.paneltitledetails}
                            variant="subtitle1"
                          >
                            Dirección:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography color={"primary"} variant="subtitle1">
                            {order.address + " - " + order.address2}
                          </Typography>
                        </Box>
                      </Box>}

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="start"
                        p={0}
                        ml={1}
                      >
                        <Box>
                          <Typography
                            color={"primary"}
                            className={styles.paneltitledetails}
                            variant="subtitle1"
                          >
                            Teléfono:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography color={"primary"} variant="subtitle1">
                            {order.phone}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="start"
                        p={0}
                        ml={1}
                      >
                        <Box>
                          <Typography
                            color={"primary"}
                            className={styles.paneltitledetails}
                            variant="subtitle1"
                          >
                            Tienda:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography color={"primary"} variant="subtitle1">
                            {shop.name}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="start"
                        p={0}
                        ml={1}
                      >
                        <Box>
                          <Typography
                            color={"primary"}
                            className={styles.paneltitledetails}
                            variant="subtitle1"
                          >
                            Tipo:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography color={"primary"} variant="subtitle1">
                            {order.send === '0' && 'Envío a domicilio'}
                            {order.send === '1' && 'Takeaway'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.slideButton}
                      onClick={() => {onNext();  }}
                    >
                      Finalizá el pago con tarjeta
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={styles.slideButton}
                        onClick={() => {onNext(); setCashPay(true); onCashPay()}}
                    >
                      Finalizá el pago con efectivo
                    </Button>
                  </Fragment>
                )}
              </div>
              <div>
                {index === 6 && (
                    <Fragment>
                      {!cashPay &&
                      <MercadoPagoForm
                          setRefreshOnSuccess={setRefreshOnSuccess}
                          refreshHeight={refreshHeight}
                          order={order}
                          seller={user}
                          colors={colors}
                      />
                      }
                      {cashPay &&
                      <p style={{ color: colors.textColor }} className={styles.slideTitle}>
                        {
                          order.name + `, Tu compra ha sido procesada con éxito! Cualquier consulta sobre tu pedido podés comunicarte con la tienda haciendo referencia al número de pedido`

                        }
                        {/*<Button onClick={() => handleChangeIndex(0)} color="primary" className={styles.nextButton}>
                            INTENTAR CON OTRA TARJETA
                          </Button>*/}
                      </p>
                      }

                    </Fragment>
                )}
              </div>
            </SwipeableViews>
            {index < 6 && (
              <div className={styles.bottomContainer}>
                <PageBalls colors={colors} index={index} steps={6} />
                {index < 1 && (
                  <Button
                    onClick={onNext}
                    color="primary"
                    className={styles.nextButton}
                  >
                    {"SIGUIENTE"}
                  </Button>
                )}
                {index > 0 && index < 5 && (
                  <>
                    <Button
                      onClick={onNext}
                      color="primary"
                      className={styles.nextButton}
                    >
                      {"SIGUIENTE"}
                    </Button>
                    <Button
                      onClick={onPrev}
                      color="primary"
                      className={styles.nextButton}
                    >
                      {"ANTERIOR"}
                    </Button>
                  </>
                )}
                {index > 4 && (
                  <Button
                    onClick={onPrev}
                    color="primary"
                    className={styles.nextButton}
                  >
                    {"ANTERIOR"}
                  </Button>
                )}
              </div>
            )}
          </Paper>
        </div>
      </Interactable.View>
    </ThemeProvider>
  );
}
