import React, {useState, useEffect, useRef} from 'react';
import SwipeableViews from "react-swipeable-views";
import styles from './slideup.module.scss'
import {PageBalls} from "../PageBalls/PageBalls";
import Button from "@material-ui/core/Button";
import {getBin, validateCreditCard, validateMonth, validateSecurityCode, validateYear} from "../utils/mercadopagoutils";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import * as animationData from '../animations/cardIn'
import * as animationDataSuccess from '../animations/cardSuccess'
import * as animationDataFailed from '../animations/cardFailed'
import Lottie from 'react-lottie';
import Cards from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss';
import urlencode from "urlencode";
import {colors} from "@material-ui/core";
import { getOrderToSend } from "../utils/FireBaseServices";

export function MercadoPagoForm({order, refreshHeight, setRefreshOnSuccess, seller, colors}) {
    const [isStopped, setIsStopped]= useState(false);
    const [isPaused, setIsPaused]= useState(false);
    const [defaultOptions, setDefaultOptions]= useState({
        loop: false,
        autoplay: false,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }});
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [pending, setPending] = useState(false);
    const [animMessageShown, setAnimMessageShown] = useState(false);

    const lottie = useRef(null);

    useEffect(() => {
        window.Mercadopago.setPublishableKey(seller.public_key);
        window.Mercadopago.getIdentificationTypes(function (status, res) {
            console.log(res);
            setDocTypes(res);
        });

    },[]);



    const [paymentMethod, setThePayMet] = useState('');
    const [paySend, setPaySend] = useState(false);
    const [invalidCard, setInvalidCard] = useState(false);
    const [docTypes, setDocTypes] = useState([]);
    const [index, setIndex] = useState(0);
    const [installments, setInstallments] = useState([]);
    const [payOptions, setPayOptions] = useState('');
    const [error, setError] = useState(false);

    const [cardholderName, setCardholderName] = useState('');
    const [cardnumber, setCardnumber] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [cardExpirationMonth, setCardExpirationMonth] = useState('');
    const [cardExpirationYear, setCardExpirationYear] = useState('');
    const [docType, setDocType] = useState('');
    const [docNumber, setDocNumber] = useState('');
    const [installment, setInstallment] = useState('');
    const [amount, setAmount] = useState(150);
    const [quantity, setQuantity] = useState(1);

    const [shareText, setShareText] = useState('');

    const handleChangeIndex = newIndex => {
        setIndex(newIndex)
    };
    useEffect(()=>{
        if (index === 1){
            if(!cardholderName){
                setIndex(index - 1)
                setError(true)
            }
        }
        else if (index === 2){
            if(!validateCreditCard(cardnumber) || !validateSecurityCode(securityCode)){
                setIndex(index - 1)
                setError(true)
            }
        }
        else if (index === 3){
            if (!validateMonth(cardExpirationMonth) || !validateYear(cardExpirationYear || !validateCreditCard(cardnumber))) {
                setIndex(index - 1)
                setError(true)
            }else {
                getInstallments()
            }
        }
        else if (index === 4){
            if(!validateDoc(docNumber) || !docType){
                setIndex(index - 1)
                setError(true)
            }
        }
        else if (index === 5){
            if(!paySend){
                setIndex(index - 1)
            }
            else {
                refreshHeight()
            }
        }
        else if (index === 6){
            refreshHeight()
        }


    },[index]);

    const onNext = () =>{
        setIndex(prevIndex => prevIndex + 1);
    };

    const onPrev = () =>{
        setIndex(prevIndex => prevIndex - 1);
    };

    const [focus, setFocus] = useState('')

    const handleInputFocus = (e) => {
        setFocus( e.target.name);
    }
    const onChange = name => (event) =>{
        setError(false);
        if (name === 'cardholderName'){
            setCardholderName(event.target.value)
        }else if (name === 'cardnumber'){
            guessingPaymentMethod(event);
            setCardnumber(event.target.value)
        }
        else if (name === 'quantity'){
            setQuantity(event.target.value)
        }
        else if (name === 'securityCode'){
            setSecurityCode(event.target.value)
        }
        else if (name === 'cardExpirationMonth'){
            setCardExpirationMonth(event.target.value)
        }
        else if (name === 'cardExpirationYear'){
            setCardExpirationYear(event.target.value)
        }
        else if (name === 'docType'){
            setDocType(event.target.value)
        }
        else if (name === 'docNumber'){
            setDocNumber(event.target.value)
        }
        else if (name === 'installment'){
            setInstallment(event.target.value)
        }

    };

    const guessingPaymentMethod = (event) => {
        var bin = getBin();
        if (event.type == "keyup") {
            if (bin.length >= 6) {
                window.Mercadopago.getPaymentMethod({
                    "bin": bin
                }, setPaymentMethodInfo);
            }
        } else {
            setTimeout(function() {
                if (bin.length >= 6) {
                    window.Mercadopago.getPaymentMethod({
                        "bin": bin
                    }, setPaymentMethodInfo);
                }
            }, 100);
        }
    };

    const setPaymentMethodInfo = (status, response) => {
        if (status == 200) {
            const paymentMethodElement = document.querySelector('input[name=paymentMethodId]');
            setThePayMet(response[0]);

            if (paymentMethodElement) {
                paymentMethodElement.value = response[0].id;
            } else {
                const input = document.createElement('input');
                input.setattribute('name', 'paymentMethodId');
                input.setAttribute('type', 'hidden');
                input.setAttribute('value', response[0].id);
                var form = document.querySelector('#pay');
                form.appendChild(input);
            }
        } else {
            setInvalidCard(true);

        }
    };

    const validateDoc = (doc) =>{
        var theDocType = docTypes.find(x => x.id === docType);
        if (doc.length >= theDocType.min_length && doc.length <= theDocType.max_length){
            return true
        }
        else {return false}
    };

    const getInstallments = () => {
        window.Mercadopago.getInstallments({
            "bin": getBin(),
            "amount": parseFloat(order.total)
        }, (status, res) => {
            console.log(status, res);
            if (status === 200){
                setInstallments(res[0].payer_costs);
                setPayOptions(res[0])
            }
            else {
                setIndex(index - 2);
                setError(true);
            }
        })
    };



    function onCompleteLottie () {

        let successVar = lottie.current.props.success;
        let failedVar = lottie.current.props.failed;
        let pendingVar = lottie.current.props.pending;
        let animMessageShownVar = lottie.current.props.animMessageShown;

        if (!successVar && !failedVar && !pendingVar){
            lottie.current.stop();
            lottie.current.play();
        }
        else if(successVar && !animMessageShownVar){
            console.log('success')
            setDefaultOptions(
                {
                    loop: false,
                    autoplay: false,
                    animationData: animationDataSuccess.default,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }
            )
            setAnimMessageShown(true);
            lottie.current.stop();
            lottie.current.play();
        }
        else if(failedVar && !animMessageShownVar){
            setDefaultOptions(
                {
                    loop: false,
                    autoplay: false,
                    animationData: animationDataFailed.default,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }
            )
            setAnimMessageShown(true);

            lottie.current.stop();
            lottie.current.play();
        }
        else if(pendingVar && !animMessageShownVar){
            setAnimMessageShown(true);
            lottie.current.stop();
            lottie.current.play();
        }
        else if (animMessageShownVar){
            handleChangeIndex(6);
            setRefreshOnSuccess(true);
        }

    };





    const onBuySubmit = () =>{
        if (installment){
            setPaySend(true)
            handleChangeIndex(5);
            setTimeout( () => {
                lottie.current.play();
            },100);
            var $form = document.querySelector('#pay');
            console.log($form);
            window.Mercadopago.createToken($form, (status, res) => {
                console.log(res);
                var payment_data = {};
                let application_fee = parseFloat((parseFloat(order.total) * parseFloat(seller.marketplace_fee) / 100).toFixed(2));
                console.log(application_fee);

                if(payOptions.processing_mode === 'aggregator'){
                    let payer2 = {
                        "first_name": "Juan",
                        "last_name": "Stein",
                        "address": {
                            "zip_code": "1426",
                            "street_name": "Amenabar",
                            "street_number": 80
                        },
                        "phone":{
                            "area_code":"011",
                            "number":"58072655"
                        }
                    }

                    payment_data = {
                        binary_mode: true,
                        transaction_amount: parseFloat(order.total),
                        "notification_url":"https://us-central1-itesacheckouts.cloudfunctions.net/notification",
                        token: res.id,
                        description: seller.id,
                        installments: installment,
                        payment_method_id: payOptions.payment_method_id,
                        issuer_id: payOptions.issuer.id,
                        payer: {
                            email: order.mail,
                        },
                        /*"additional_info":{
                            "items":[
                                {
                                    "title":seller.id,
                                    "quantity":1,
                                    "unit_price": parseFloat(order.total)
                                }
                            ],
                            "payer":payer2,
                        }*/
                    };
                }else {
                    payment_data = {
                        binary_mode: true,
                        transaction_amount: parseFloat(order.total),
                        token: res.id,
                        "notification_url":"https://us-central1-itesacheckouts.cloudfunctions.net/notification",
                        description: seller.id,
                        installments: installment,
                        payment_method_id: payOptions.payment_method_id,
                        issuer_id: payOptions.issuer.id,
                        processing_mode: payOptions.processing_mode,
                        payment_method_option_id: payOptions.agreements[0].merchant_accounts[0].payment_method_option_id,
                        payer: {
                            email: order.mail,
                        },
                    };
                }
                if (res.id){
                    const orderToSend = getOrderToSend(seller.id, order);
                    let data = {
                        order: orderToSend,
                        payment: payment_data,
                        seller: seller
                    }

                    fetch('https://us-central1-itesacheckouts.cloudfunctions.net/pay', {
                        method: 'POST', // or 'PUT'
                        body: JSON.stringify(data), // data can be `string` or {object}!
                        headers:{
                            'Content-Type': 'application/json'
                        }
                    }).then(res => {
                        res.json().then((val) => {
                            console.log(val);
                            if (val.response.status === 'approved'){
                                setSuccess(true);
                            }
                            else if (val.response.status === 'rejected'){
                               setFailed(true)
                            }
                            else if (val.response.status === 'in_process'){
                                setPending(true)
                            }

                        }).catch( (error) => {
                            console.log(error);
                            setFailed(true)
                        });
                    }).catch( (error) => {
                        console.log(error);
                        setFailed(true)
                    });
                }else {
                    setFailed(true)
                }
                console.log(payment_data);
            });
        }
        else {
           setError(true)
        }
    };

    const testAsPay = () => {
        setPaySend(true)
        handleChangeIndex(5);
        setTimeout( () => {
            lottie.current.play();
        },100);
        setTimeout(() => {
            setSuccess(true);
            fetch('https://us-central1-mktviral.cloudfunctions.net/sendEmailPay', {
                method: 'POST',
                body: JSON.stringify(order),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if(response.ok) {
                        console.log(response);
                    } else {
                        console.error("Error guardando los datos. \n", response);
                    }
                });
        },5000)
    };

    return (
        <div>
            {index < 5 &&
                <Cards
                    cvc={securityCode}
                    expiry={cardExpirationMonth + cardExpirationYear}
                    focused={focus}
                    name={cardholderName}
                    number={cardnumber}
                />
            }
            <div style={{color: colors.textColor}} className={styles.slideTitle}>
                <span>{'Tu total es: $' + order.total }</span>
            </div>
            <form action="" method="post" id="pay" name="pay">
            <SwipeableViews enableMouseEvents={true} onChangeIndex={handleChangeIndex} index={index}>
                <div>
                   {/* <p className={styles.slideTitle}> {
                        'Ingresa el nombre del titular'
                    } </p>*/}

                    <TextField
                        type="text" id="cardholderName"
                        value={cardholderName}
                        onChange={onChange('cardholderName')}
                        error={error}
                        name="name"
                        label="Ingresa el nombre del titular"
                        className={colors.isLight ? styles.fieldDark : styles.field}
                        margin="normal"
                        variant="outlined"
                        inputProps={{'data-checkout': "cardholderName"}}
                        onFocus={handleInputFocus}
                    />
                </div>
                <div>
                    <TextField
                        type="number" id="cardNumber"
                        placeholder="4509 9535 6623 3704"
                        value={cardnumber}
                        onChange={onChange('cardnumber')}
                        onPaste={() =>{return false}} onCopy={() =>{return false}}
                        onCut={() =>{return false}} onDrag={() =>{return false}}
                        onDrop={() =>{return false}} autoComplete='off'
                        error={error}
                        label="Numero de tarjeta"
                        className={colors.isLight ? styles.fieldDark : styles.field}
                        margin="normal"
                        variant="outlined"
                        name="number"
                        inputProps={{'data-checkout': "cardNumber",}}
                        onFocus={handleInputFocus}
                    />
                    <TextField
                        type="number" id="securityCode"
                        placeholder="123"
                        value={securityCode}
                        onChange={onChange('securityCode')}
                        onPaste={() =>{return false}} onCopy={() =>{return false}}
                        onCut={() =>{return false}} onDrag={() =>{return false}}
                        onDrop={() =>{return false}} autoComplete='off'
                        error={error}
                        label="Codigo de seguridad"
                        className={colors.isLight ? styles.fieldDark : styles.field}
                        margin="normal"
                        variant="outlined"
                        name="cvc"
                        onFocus={handleInputFocus}
                        inputProps={{'data-checkout': "securityCode",}}
                    />
                </div>
                <div>
                    <TextField
                        type="number" id="cardExpirationMonth"
                        placeholder="12"
                        name="expiry"
                        onFocus={handleInputFocus}
                        value={cardExpirationMonth}
                        onChange={onChange('cardExpirationMonth')}
                        onPaste={() =>{return false}} onCopy={() =>{return false}}
                        onCut={() =>{return false}} onDrag={() =>{return false}}
                        onDrop={() =>{return false}} autoComplete='off'
                        error={error}
                        label="Mes de vencimiento"
                        className={colors.isLight ? styles.fieldDark : styles.field}
                        margin="normal"
                        variant="outlined"
                        inputProps={{'data-checkout': "cardExpirationMonth",}}
                    />
                    <TextField
                        type="number" id="cardExpirationYear"
                        placeholder="2022"
                        value={cardExpirationYear}
                        onChange={onChange('cardExpirationYear')}
                        onPaste={() =>{return false}} onCopy={() =>{return false}}
                        onCut={() =>{return false}} onDrag={() =>{return false}}
                        onDrop={() =>{return false}} autoComplete='off'
                        error={error}
                        label="Año de vencimiento"
                        className={colors.isLight ? styles.fieldDark : styles.field}
                        margin="normal"
                        variant="outlined"
                        inputProps={{'data-checkout': "cardExpirationYear",}}
                    />
                </div>
                <div>
                    <FormControl variant="outlined" className={colors.isLight ? styles.fieldDark : styles.field}>
                        <InputLabel
                            htmlFor="outlined-age-simple"
                        >
                            Tipo de documento
                        </InputLabel>
                        <Select
                            value={docType}
                            onChange={onChange('docType')}
                            id="docType"
                            inputProps={{
                                'data-checkout': "docType",
                            }}
                            input={
                                <OutlinedInput
                                    className={styles.selectInput}
                                    labelWidth={180}
                                />
                            }
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {docTypes.map((result, idx) =>(
                                <MenuItem key={idx} value={result.id}>{result.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        type="number" id="docNumber"
                        placeholder="12345678"
                        value={docNumber}
                        onChange={onChange('docNumber')}
                        error={error}
                        label="Numero de documento"
                        className={colors.isLight ? styles.fieldDark : styles.field}
                        margin="normal"
                        variant="outlined"
                        inputProps={{'data-checkout': "docNumber",}}
                    />
                    <input type="hidden" name="paymentMethodId"/>
                </div>
                <div>
                    <FormControl variant="outlined"  className={colors.isLight ? styles.fieldDark : styles.field}>
                        <InputLabel
                            htmlFor="outlined-age-simple"
                        >
                            Cuotas
                        </InputLabel>
                        <Select
                            value={installment}
                            onChange={onChange('installment')}
                            input={
                                <OutlinedInput
                                    className={styles.selectInput}
                                    labelWidth={90}
                                />
                            }
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {installments.map((result, idx) =>(
                                <MenuItem key={idx} value={result.installments}>
                                    {result.recommended_message}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="primary"
                            className={styles.slideButton} onClick={onBuySubmit}>
                       PAGAR
                    </Button>
                </div>
                
                <div>
                    {index >= 5 &&
                    <Lottie
                        ref={lottie}
                        success={success}
                        pending={pending}
                        failed={failed}
                        animMessageShown={animMessageShown}
                        isStopped={isStopped}
                        isPaused={isPaused}
                        width={200}
                        options={defaultOptions}
                        eventListeners={[{eventName: 'complete',callback:  onCompleteLottie }]}
                    />}
                </div>

                <div>
                    <p style={{ color: colors.textColor }} className={styles.slideTitle}>
                        {success &&
                        order.name + `, Tu compra ha sido procesada con éxito! Cualquier consulta sobre tu pedido podés comunicarte con la tienda haciendo referencia al número de pedido`
                        }
                        {failed &&
                        'Ups! Tu pago no fue aprobado. Por favor volvé a intentarlo'
                        }
                        {failed &&
                        <Button onClick={() => handleChangeIndex(0)} color="primary" className={styles.nextButton}>
                            INTENTAR CON OTRA TARJETA
                        </Button>
                        }
                    </p>
                </div>

            </SwipeableViews>
            </form>

            {/*<a href={'https://getviral.me/'}>
                <img src={poweredBy} className={styles.poweredBy} alt={'poweredBy'}/>
            </a>*/}
            <div className={styles.bottomContainer}>
                {index <= 4 &&
                    <PageBalls colors={colors} index={index} steps={5}/>
                }

                {index < 1 && (
                  <Button
                    onClick={onNext}
                    color="primary"
                    className={styles.nextButton}
                  >
                    {"SIGUIENTE"}
                  </Button>
                )}
                {index > 0 && index < 4 && (
                  <>
                    <Button
                      onClick={onNext}
                      color="primary"
                      className={styles.nextButton}
                    >
                      {"SIGUIENTE"}
                    </Button>
                    <Button
                      onClick={onPrev}
                      color="primary"
                      className={styles.nextButton}
                    >
                      {"ANTERIOR"}
                    </Button>
                  </>
                )}
                {index > 4 && (
                  <Button
                    onClick={onPrev}
                    color="primary"
                    className={styles.nextButton}
                  >
                    {"ANTERIOR"}
                  </Button>
                )}
            </div>


        </div>
    )
}