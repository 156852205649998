import firebase from './firebase'


const db = firebase.firestore();


const getOrderToSend = (userID, order) => {
    const { address2, address, shop, products, ...restOrder } = order;
    const { key, name } = shop;
    let prodsToSend = [];

    products.forEach(({ key, name, quantity }) => {
        if (quantity > 0) {
            prodsToSend.push({ key, name, quantity })
        }
    })
    return {
        ...restOrder,
        userID,
        date: Date.now(),
        address: address + ' - ' + address2,
        shop: { key, name },
        products: prodsToSend
    }
}

export { getOrderToSend };